<template>
  <div>
    <div class="top">
      <div style="flex: 1">
        <div class="left" v-if="pledgorList.length > 0">
          <div class="item">转让人名称：{{ pledgorList[0].name }}</div>
          <div class="item">
            统一社会信用代码：{{ pledgorList[0].creditCode }}
          </div>
        </div>
      </div>

      <div class="addbtn">
        <div class="addbutton" @click="add">
          <i class="el-icon-plus"></i>
        </div>
      </div>
    </div>
    <base-table
      :columns="fundSideConfig"
      :tableAttrs="{
        data: finContractInfosCapitalReq,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <template slot="finContractNameHeader">
        <font style="color: #ee1c1c">*</font>合同名称
      </template>
      <template slot="finContractNamesHeader">
        <font style="color: #ee1c1c">*</font>实际合同名称
      </template>
      <template slot="pledgeValueHeader">
        <font style="color: #ee1c1c">*</font>转让价值(元)
      </template>
      <template slot="relLoanAmountHeader">
        <font style="color: #ee1c1c">*</font>对应融资额(元)
      </template>
      <template slot="pledgeRateHeader">
        <font style="color: #ee1c1c">*</font>质押率(%)
      </template>
      <template slot="finContractName" slot-scope="scope">
        <base-select
          v-model="scope.row.finContractId"
          filterable
          @change="
            finContractChange(
              scope.row,
              scope.row.finContractId,
              pledgorList[0] ? pledgorList[0].contractData : [],
              finContractInfosCapitalReq,
              scope.$index
            )
          "
          :options="pledgorList[0] ? pledgorList[0].contractData : []"
          :selectedField="['keyId', 'contractName']"
        ></base-select>
      </template>
      <template slot="finContractNames" slot-scope="scope">
        <sz-input
          v-model="scope.row.finContractName"
          placeholder="请输入"
        ></sz-input>
      </template>
      <template slot="projectDebtor" slot-scope="scope">
        <sz-input
          v-model="scope.row.projectDebtor"
          placeholder="请输入"
        ></sz-input>
      </template>
      <template slot="finContractCode" slot-scope="scope">
        <sz-input
          v-model="scope.row.finContractCode"
          placeholder="请输入"
          :disabled="true"
        ></sz-input>
      </template>
      <template slot="invoiceNo" slot-scope="scope">
        <sz-input v-model="scope.row.invoiceNo" placeholder="请输入"></sz-input>
      </template>
      <template slot="finContractAmount" slot-scope="scope">
        <amount-input
          v-model="scope.row.finContractAmount"
          placeholder="请输入"
          @input="finContractAmountInput(scope.row.finContractAmount,scope.$index)"
          :maxlength='16'
        ></amount-input>
      </template>
      <template slot="pledgeValue" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeValue"
          placeholder="请输入"
          maxlength="16"
          @input="pledgeValueChange(scope.row.pledgeValue, scope.$index)"
        ></amount-input>
      </template>
      <template slot="relLoanAmount" slot-scope="scope">
        <amount-input
          v-model="scope.row.relLoanAmount"
          placeholder="请输入"
          maxlength="16"
          @input="changeRelLoanAmount(scope.row.relLoanAmount, scope.$index)"
        ></amount-input>
      </template>
      <template slot="pledgeRate" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeRate"
          placeholder="请输入"
          maxlength="16"
          :disabled="true"
        ></amount-input>
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton
          content="删除"
          @click="remove(scope.row, scope.$index)"
          icon="iconfont iconshanchu1"
        ></IconButton>
      </template>
    </base-table>
  </div>
</template>

<script>
import SzInput from '@/components/input/sz-input/sz-input.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { fundSideConfig } from '../utils/config'
export default {
  components: { BaseTable, IconButton, BaseSelect, AmountInput, SzInput },
  props: {
    pledgorList: Array,
    finContractInfosCapitalReq: Array
  },
  data () {
    return {}
  },
  // 计算属性 类似于data概念
  computed: {
    fundSideConfig () {
      return fundSideConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {
    finContractInfosCapitalReq: {
      handler (val) {
        this.$emit('changeFundSide', val)
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    // 合同对价
    finContractAmountInput (data, index) {
      if (data - 10000000000 > 0) {
        this.warning('合同对价不能大于10亿')
        this.$set(this.finContractInfosCapitalReq[index], 'finContractAmount', '')
      }
    },
    // 对应融资额修改
    changeRelLoanAmount (value, index) {
      if (value && this.finContractInfosCapitalReq[index].pledgeValue) {
        this.$set(
          this.finContractInfosCapitalReq[index],
          'pledgeRate',
          (value / this.finContractInfosCapitalReq[index].pledgeValue * 100).toFixed(2)
        )
      }
      if (!value) {
        this.$set(
          this.finContractInfosCapitalReq[index],
          'pledgeRate',
          ''
        )
      }
    },
    // 转让价值修改
    pledgeValueChange (value, index) {
      if (value === '0' || !value) {
        this.warning('请输入转让价值且转让价值必须大于0')
        return false
      }
      if (value && this.finContractInfosCapitalReq[index].relLoanAmount) {
        this.$set(
          this.finContractInfosCapitalReq[index],
          'pledgeRate',
          (this.finContractInfosCapitalReq[index].relLoanAmount / value * 100).toFixed(2)
        )
      }
    },
    finContractChange (data, item, list, dataList, index) {
      let status = true
      if (this.contractInfoData) {
        const isSelectCode = this.contractInfoData.some(
          (item) => item.finContractId === data.finContractId
        )
        if (isSelectCode) {
          this.$set(data, 'finContractId', '')
          return this.warning('合同不能相同，请重新选择')
        }
      }
      dataList.forEach((ele, indexs) => {
        if (ele.keyIds === item && index !== indexs) {
          status = false
        }
      })
      if (!status) {
        this.$set(data, 'finContractId', '')
        return this.warning('合同不能相同，请重新选择')
      }

      list.forEach((ele) => {
        if (ele.keyId === item) {
          if (ele.pledgeValue <= '0') {
            this.warning('转让价值必须大于0')
          }
          data.projectDebtor = ele.xmgsName
          data.finContractCode = ele.contractCode
          data.finContractAmount = ele.contractAmount
          this.$set(data, 'finContractName', ele.contractName)
          data.contractName = ele.contractName
          data.pledgeValue = ele.pledgeValue
          data.keyIds = ele.keyId // 用户补充合同查询过滤
        }
      })
      if (!item) {
        data.projectDebtor = ''
        data.finContractCode = ''
        this.$set(data, 'finContractName', '')
        data.finContractAmount = ''
        data.contractName = ''
        data.pledgeValue = ''
        data.relLoanAmount = ''
        data.keyIds = ''
      }
    },
    add () {
      const data = {
        finContractName: '',
        projectDebtor: '',
        finContractId: '',
        finContractCode: '',
        finContractAmount: '',
        pledgeValue: '',
        relLoanAmount: ''
      }
      this.finContractInfosCapitalReq.push(data)

      this.$emit('clearRepaymentPlan', '')
    },
    remove (row, index) {
      this.finContractInfosCapitalReq.splice(index, 1)

      this.$emit('clearRepaymentPlan', '')
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    flex: 1;
    display: flex;
    .item {
      color: #38373a;
      font-size: 14px;
      padding-right: 150px;
    }
  }
  .addbtn {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;
    padding-right: 20px;
    .addbutton {
      background: #4A7CF0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;

      cursor: pointer;
      &:hover {
        background-color: #6996FF;
      }
    }
  }
}
</style>
